<template>
  <div class="container">
    <div class="selectedParams">
      <div class="headerContent">
        <h2>Filtros de búsqueda</h2>
        <vs-button icon @click="showFilters = !showFilters">
          <i v-if="showFilters" class="fas fa-chevron-up"></i>
          <i v-else class="fas fa-chevron-down"></i>
        </vs-button>
      </div>

      <div v-if="showFilters">
        <vs-select filter class="inputForm" placeholder="Mis filtros guardados">Mis filtros</vs-select>
        <div class="selectYears">
          <vs-checkbox val="edad" v-model="options">
            Edad
          </vs-checkbox>
          <vue-slider
            v-if="options.includes('edad')"
            v-model="filters.ageRange"
            :tooltip-formatter="'{value} Años'"
            :enable-cross="false"
            :min="0"
            :max="100"
            :adsorb="true"
            :interval="10"
            :marks="true"
            width="80%"
            style="margin:auto"
          />
        </div>
        <div class="selectYears">
          <vs-checkbox val="gender" v-model="options">
            Género
          </vs-checkbox>
          <vs-switch
            v-if="options.includes('gender')"
            class="inputForm"
            color="#7d33ff"
            v-model="filters.gender"
          >
            <template #off>
              Hombre
            </template>
            <template #on>
              Mujer
            </template>
            <template #circle>
              <i v-if="!filters.gender" class="fas fa-mars"></i>
              <i v-else class="fas fa-venus"></i>
            </template>
          </vs-switch>
        </div>
        <div class="selectYears">
          <vs-checkbox val="protocols" v-model="options">
            Protocolos
          </vs-checkbox>
          <vs-select
            v-if="options.includes('protocols')"
            label-placeholder="Selecciona los protocolos:"
            filter
            multiple
            v-model="filters.protocols"
          >
            <vs-option-group>
              <div slot="title">
                Personalizados
              </div>
              <vs-option label="Protocolo 1" value="1">
                Protocolo 1
              </vs-option>
              <vs-option label="Protocolo 2" value="2">
                Protocolo 2
              </vs-option>
              <vs-option label="Protocolo 3" value="3">
                Protocolo 3
              </vs-option>
            </vs-option-group>
            <vs-option-group>
              <div slot="title">
                Estandarts
              </div>
              <vs-option label="Knee" value="4">
                Knee S.
              </vs-option>
              <vs-option label="Typescript" value="5">
                Oxford S.
              </vs-option>
              <vs-option label="Webpack" value="6">
                WOMAK
              </vs-option>
              <vs-option label="Nodejs" value="7">
                AKSS
              </vs-option>
              <vs-option label="Nodejs" value="8">
                EVA
              </vs-option>
            </vs-option-group>
          </vs-select>
        </div>
        <div class="selectYears">
          <vs-checkbox val="periods" v-model="options">
            Periodicidad
          </vs-checkbox>
          <vs-select
            v-if="options.includes('periods')"
            filter
            multiple
            placeholder="Selecciona los periodos"
            v-model="filters.periods"
          >
            <vs-option-group>
              <div slot="title">
                Pre Operatorio
              </div>
              <vs-option label="Pre Operatorio" value="-1">
                Pre Operatorio
              </vs-option>
            </vs-option-group>
            <vs-option-group>
              <div slot="title">
                Post Operatorio
              </div>
              <vs-option label="15 días" value="15">
                15 días
              </vs-option>
              <vs-option label="1 mes" value="30">
                1 mes
              </vs-option>
              <vs-option label="2 meses" value="60">
                2 meses
              </vs-option>
              <vs-option label="3 meses" value="90">
                3 meses
              </vs-option>
              <vs-option label="6 meses" value="180">
                6 meses
              </vs-option>
              <vs-option label="1 año" value="360">
                1 año
              </vs-option>
            </vs-option-group>
          </vs-select>
        </div>
        <div class="selectYears">
          <vs-checkbox val="interventionType" v-model="options">
            Tipo de intervención
          </vs-checkbox>
          <vs-select
            v-if="options.includes('interventionType')"
            filter
            multiple
            placeholder="Selecciona el tipo de intervención"
            v-model="filters.interventionType"
          >
            <vs-option label="Operación de rodilla" value="1">
              Operación de rodilla
            </vs-option>
            <vs-option label="Operación de cadera" value="2">
              Operación de cadera
            </vs-option>
          </vs-select>
        </div>
        <div class="hojasSelects">
          <vs-checkbox val="hojasQ" v-model="options">
            Propiedades de la hoja Quirúrgica
          </vs-checkbox>
          
            <vs-select
            v-if="options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.region === '' ? 'warn' : 'success'"
              placeholder="Selecciona la región"
              v-model="hojas.region"
            >
              <vs-option label="Extremidad Inf." value="1">
                Extremidad Inf.
              </vs-option>
              <vs-option label="Extremidad Sup." value="2">
                Extremidad Sup.
              </vs-option>
              <vs-option label="Columna" value="3"> Columna </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.region && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.articulacion === '' ? 'warn' : 'success'"
              placeholder="Selecciona la articulación"
              v-model="hojas.articulacion"
            >
              <vs-option label="Cadera" value="1"> Cadera </vs-option>
              <vs-option label="Rodilla" value="2"> Rodilla </vs-option>
              <vs-option label="Pie y tobillo" value="3">
                Pie y tobillo
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.articulacion && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.type === '' ? 'warn' : 'success'"
              placeholder="Selecciona el tipo de cirugía"
              v-model="hojas.type"
            >
              <vs-option label="Primária" value="1"> Primária </vs-option>
              <vs-option label="Revisión" value="2"> Revisión </vs-option>
              <vs-option label="Artroscópia" value="3"> Artroscópia </vs-option>
              <vs-option label="Fractura" value="4"> Fractura </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.type && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.subtype === '' ? 'warn' : 'success'"
              placeholder="Tipo de Artroscópia"
              v-model="hojas.subtype"
            >
              <vs-option label="Ligamento Cruzado Anterior" value="1">
                Ligamento Cruzado Anterior
              </vs-option>
              <vs-option label="Ligamento Cruzado Posterior" value="2">
                Ligamento Cruzado Posterior
              </vs-option>
              <vs-option label="Lesión meniscal" value="3">
                Lesión meniscal
              </vs-option>
              <vs-option label="Lesión Condral" value="4">
                Lesión Condral
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.subtype && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.lesion === '' ? 'warn' : 'success'"
              placeholder="Lesión"
              v-model="hojas.lesion"
            >
              <vs-option label="Ruptura" value="1"> Ruptura </vs-option>
              <vs-option label="Revisión" value="2"> Revisión </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.lesion && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.plastiaType === '' ? 'warn' : 'success'"
              placeholder="Tipo de Artroscópia"
              v-model="hojas.plastiaType"
            >
              <vs-option label="Isquiotibiales" value="1">
                Isquiotibiales
              </vs-option>
              <vs-option label="Tendón rotuliano" value="2">
                Tendón rotuliano
              </vs-option>
              <vs-option label="Aloinjerto" value="3"> Aloinjerto </vs-option>
              <vs-option label="Otro" value="4"> Otro </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.plastiaType && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.fix1 === '' ? 'warn' : 'success'"
              placeholder="Fijación proximal"
              v-model="hojas.fix1"
            >
              <vs-option label="Tornillo interferencial" value="1">
                Tornillo interferencial
              </vs-option>
              <vs-option label="Sistema de suspensión" value="2">
                Sistema de suspensión
              </vs-option>
            </vs-select>

            <vs-select
              v-if="hojas.plastiaType && options.includes('hojasQ')"
              filter
              multiple
              class="inputForm"
              :state="hojas.fix2 === '' ? 'warn' : 'success'"
              placeholder="Fijación distal"
              v-model="hojas.fix2"
            >
              <vs-option label="Tornillo interferencial" value="1">
                Tornillo interferencial
              </vs-option>
              <vs-option label="Sistema de suspensión" value="2">
                Sistema de suspensión
              </vs-option>
            </vs-select>
          
          </div>
      </div>
      <div v-else>
      <small> Sin filtros seleccionados, se mostrarán todos los datos. </small>
      </div>
    </div>
    <div class="btnContent">
      <vs-button color="#1d6f42" @click="exportExcel">
        <i class="fas fa-file-excel" />Exportar a Excel
      </vs-button>
      <vs-button v-if="options.length > 0" @click="active3 = true">
        Guardar Selección
      </vs-button>
    </div>

    <div>
      <iframe width="100%" height="500px" src="https://lookerstudio.google.com/embed/reporting/665213bf-0470-4255-a0a4-c451d6301add/page/W2qED" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>

    <vs-table id="dbTable">
      <template #header>
        <vs-input v-model="search" border placeholder="Search" />
      </template>
      <template #thead>
        <vs-tr>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'nombre')">
            Name
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'name')">
            Fecha IQ
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'email')">
            Edad
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'id')">
            Genero
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'id')">
            Oxford 2m
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'id')">
            Oxford 6m
          </vs-th>
          <vs-th sort @click="groups = $vs.sortData($event, groups, 'id')">
            Oxford 1a
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr
          :key="i"
          v-for="(tr, i) in $vs.getSearch(groups, search)"
          :data="tr"
        >
          <vs-td>
            {{ tr.nombre }}
          </vs-td>
          <vs-td>
            {{ tr.dateIQ }}
          </vs-td>
          <vs-td>
            {{ tr.edad }}
          </vs-td>
          <vs-td>
            <div v-if="tr.gender === 1" class="userDataItem">
              <i class="fas fa-male"></i>
            </div>
            <div v-else-if="tr.gender === 2" class="userDataItem">
              <i class="fas fa-female"></i>
            </div>
          </vs-td>
          <vs-td>
            {{ tr.oxford2m }}
          </vs-td>
          <vs-td>
            {{ tr.oxford6m }}
          </vs-td>
          <vs-td>
            {{ tr.oxford1a }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-dialog width="300px" not-center v-model="active3">
      <template #header>
        <h4 class="not-margin">Selecciona un <b>Nombre</b></h4>
      </template>

      <div class="con-content">
        <vs-input v-model="name" placeholder="Nombre"></vs-input>
      </div>

      <template #footer>
        <div class="btnContent">
          <vs-button danger @click="active3 = false">
            Cancelar
          </vs-button>
          <vs-button success @click="saveSelection">
            Guardar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { databaseMix, sheetQMix } from "@/mixins/mixins.js";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "DatabasePage",
  mixins: [databaseMix, sheetQMix],
  components: {
    VueSlider,
  },
  data() {
    return {
      search: "",
      active3: false,
      name: "",
      showFilters: true,
      allFolders: [],
      selectedFolders: [],
      selectedLV: [],
      hojas:{
        region: "",
        articulacion: "",
        type: "",
        subtype: "",
        lesion: "",
        plastiaType: "",
        fix1: "",
        fix2: "",
      },
      groups: [
        {
          nombre: "Eduardo Gonzalez Perez",
          dateIQ: null,
          edad: 45,
          gender: 1,
          oxford2m: 30,
          oxford6m: 56,
          oxford1a: 78,
        },
        {
          nombre: "Pedro Gomez Hernandez",
          dateIQ: null,
          edad: 51,
          gender: 2,
          oxford2m: 40,
          oxford6m: 62,
          oxford1a: 85,
        },
      ],
      options: [],
      filters: {
        ageRange: [30, 40],
        gender: false,
        protocols: [],
        periods: [],
        interventionType: [],
      },
    };
  },
  methods: {
    async saveSelection() {
      const data = this.filters;
      data.name = this.name;
      await this.saveFilterParams(data);
      this.active3 = false;
    },
    exportExcel() {
      const downloadLink = document.createElement("a");
      const dataType = "application/vnd.ms-excel";
      let tableElement = document.getElementById("dbTable");
      tableElement = tableElement.children[1].children[0];
      const tableHTML = tableElement.outerHTML.replace(/ /g, "%20");
      const filename = "clinikForm_db.xls";
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    async loadFolders(){
      const folders = await this.getSheets();
      console.log(folders);
      this.selectedLV = '0';
      this.allFolders = folders;
      this.selectedFolders = filterByLV(folders, this.selectedLV);
    }
  },
  mounted() {
    const options = {
      //   weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    const today = new Date().toLocaleDateString("en-US", options);
    this.groups.forEach((element) => {
      element.dateIQ = today;
    });
    this.loadFolders();
  },
};

const filterByLV = (folders, parent) => folders.filter((folder)=> folder.parent === parent);
</script>

<style scoped>

h2, .h2 {
    font-size: 1.5rem;
}

.inputForm {
  margin: 2rem 1rem 2rem 1rem;
  min-width: 100%;
}

.inputForm {
  min-width: 50%;
}

.container {
  text-align: justify;
  padding: 4rem 1rem 0rem 4.5rem;
}

.headerContent,
.btnContent {
  display: flex;
  justify-content: space-between;
}

.selectYears {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.userActions,
.userData {
  display: flex;
}

.userDataItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: start;
}
</style>
